import { notification } from 'antd';

export const NotificationInfo = (title, description, placement) => {
    notification.info({
      message: title,
      description: description.toUpperCase(),
      placement,
    });
};

export const NotificationError = (title, description, placement) => {
  notification.error({
    message: title,
    description: description.toUpperCase(),
    placement,
  });
};
