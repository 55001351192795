import React, { useEffect, useRef, useState } from 'react'
import Layout from "antd/es/layout/layout";
import styles from './response-train-css/response-train.module.css'
import Lodash from 'lodash'
import { Button, Dropdown, Menu, Col, Collapse, Divider, Input, Row, Select, Space, Tabs, Modal, Slider } from 'antd';
import CTable from "../global/table";
import CModal from "../global/modal";
import { DeleteOutlined, DeliveredProcedureOutlined, FormOutlined, LinkedinOutlined, LinkOutlined, MailOutlined, PlaySquareOutlined, PlusOutlined, ReloadOutlined, RotateRightOutlined } from "@ant-design/icons";
import { request } from "../../../api_request";
import { connect } from "react-redux";
// import responseLogPNG from "../../../assets/ai-flow/response-log.png";
// import blukTrainPNG from "../../../assets/ai-flow/bluk-train.png";
// import profanityFilterPNG from "../../../assets/ai-flow/profanity-filter.png";
import { NotificationError, NotificationInfo } from '../global/DisplayNotification';
import shortUUID from 'short-uuid';
import moment from 'moment';
import {Link, useHistory} from "react-router-dom";
import {Header} from "../../header/Header";
import SidebarStatic from "../../sidebar-static";
const { Option } = Select;
const { Panel } = Collapse;
const { TabPane } = Tabs;


function ResponseLog({ props }) {

    // const [isTestIntentModalVisible, setIsTestIntentModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isAttachModalVisible, setIsAttachModalVisible] = useState(false)
    const [historyData, setHistoryData] = useState(undefined);

    const [apiIntentData, setApiIntentData] = useState(undefined)
    const [apiUtterancesData, setApiUtterancesData] = useState(undefined)
    const [intentSelect, setIntentSelect] = useState(undefined)
    const [intentSelectId, setIntentSelectId] = useState(undefined)
    const [sliderStart, setSliderStart] = useState(0);
    const [sliderEnd, setSliderEnd] = useState(20);
    const [status, setStatus] = useState(undefined);
    const [currIntent, setCurrIntent] = useState(undefined);
    const [currUtterance, setCurrUtterance] = useState(undefined);

    const [isUtteranceAddModalVisible, setIsUtteranceAddModalVisible] = useState(false);
    const [isTestUtteranceModalVisible, setIsTestUtteranceModalVisible] = useState(false);
    const [utterancesOtherData, setUtterancesOtherData] = useState(undefined);
    const [allUtterances, setAllUtterances] = useState([]);
    const [utterances, setUtterances] = useState(undefined);
    const [totalUtterances, setTotalUtterances] = useState(undefined);
    const [intentTestText, setIntentTestText] = useState(undefined);
    const [testData, setTestData] = useState();
    const [showSelectUtterance, setShowSelectUtterance] = useState();
    const [showAllIntent, setShowAllIntent] = useState(undefined);
    const [attachIntentValue, setAttachIntentValue] = useState(undefined);
    const [attacheBtnToggle, setAttacheBtnToggle] = useState(false);
    const [selectedIntentId, setSelectedIntentId] = useState(undefined);
    const [testUtteranceVal, setTestUtteranceVal] = useState(undefined);
    const [attachIntentSelectId, setAttachIntentSelectId] = useState(undefined);
    const [editUtterModalReset, setEditUtterModalReset] = useState(false);
    const [sendForTestIntentData, setSendForTestIntentData] = useState(undefined);
    const [sendForTrainIntentData, setSendForTrainIntentData] = useState(undefined);
    const [sendForRetrainIntentData, setSendForRetrainIntentData] = useState(undefined);
    const [isSendForTestModalVisible, setIsSendForTestModalVisible] = useState(undefined);
    const [isSendForTrainModalVisible, setIsSendForTrainModalVisible] = useState(undefined);
    const [isSendForRetrainModalVisible, setIsSendForRetrainModalVisible] = useState(undefined);
    const [searchFilterValues, setSearchFilterValues] = useState(undefined);
    const history = useHistory();


    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Intent',
            dataIndex: 'intent',
            key: 'intent',
            render: (_, record) => (
                record.intent.name
            )
        },
        {
            title: 'Score',
            dataIndex: 'intent_confidence',
            key: 'intent_confidence',
            render: (_, record) => (
                Math.round(record.intent_confidence * 100)+ '%'
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: '',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={()=>{send_for_train_modal_show(record)}} title="Train"><PlaySquareOutlined /></Button>
                    <Button onClick={()=>{send_for_retrain_modal_show(record)}} title="Retrain"><ReloadOutlined /></Button>
                    {/* {record.status == 'retrained' && <Button title="Retrain"><ReloadOutlined /></Button>} */}
                    <Button onClick={()=>send_for_test_modal_show(record)} title="Send for testing"><RotateRightOutlined /></Button>
                    <Button onClick={()=>showAttachModal(record)} title="Attach"><LinkOutlined /></Button>
                    <Button onClick={()=>showDeleteModal(record)} title="Delete"><DeleteOutlined /></Button>
                    <Button title="Edit" onClick={()=>{showModal(record)}}><FormOutlined /></Button>
                </Space>
            )
        }
    ];

    const test_columns = [
        {
            title: 'Intent & Confidence',
            key: 'intent',
            dataIndex: 'intent',
            width: '25%',
            render: (_, record)=>{
                if (record.intents.length > 0) {
                    const value = record.intents[0];
                    return <Input name={'name'} value={value.name + '  ' + value.confidence} disabled={true} placeholder="Intent Name" />
                } else {
                    return <span>No intent Found</span>
                }
            }
        },
        {
            title: 'Entities & confidence',
            key: 'entities',
            dataIndex: 'entities',
            width: '25%',
            render: (_, record)=>{
                if (Lodash.isEmpty(record.entities)){
                    return <span>No Entities Found</span>
                }else{
                    return record.entities.map((v,i)=><Input name={'entities'} value={v.name + ':'+ v.role + '  ' + Math.round(v.confidence*100)+'%'} disabled={true} />);
                }
            }
        },
        {
            title: 'Traits & confidence',
            key: 'traits',
            dataIndex: 'traits',
            width: '25%',
            render: (_, record)=>{
                if (Lodash.isEmpty(record.traits)){
                    return <span>No Traits Found</span>
                }else{
                    return record.traits.map((v,i)=><Input name={'traits'} value={v.value + '  ' + Math.round(v.confidence*100)+'%'} disabled={true} />);
                }
            }
        },
        // {
        //   title: 'Confidence',
        //   key: 'confidence',
        //   dataIndex: 'confidence',
        //   width: '25%',
        //   render: (_, record)=>{
        //     //   console.log(record);
        //     return <Input value={record.confidence} disabled={true} />
        //   }
        // }
    ];

    let send_for_retrain_modal_show = (data) =>{
        setSendForRetrainIntentData(data);
        setIsSendForRetrainModalVisible(true);

        // data.status = 'retrained';

        // console.log(data);
        // setIsSendForRetrainModalVisible(false);

        // postUtterances({
        //     id: 0,
        //     name: utterances,
        //     sentence: utterances,
        //     status: data.status
        // }, intentSelectId).then((res)=>{

        // })
    }

    let send_for_train_modal_show = (data) =>{
        setSendForTrainIntentData(data);
        console.log(data)
        setIsSendForTrainModalVisible(true);
    }


    let send_for_test_modal_show = (data) =>{
        setSendForTestIntentData(data);
        setIsSendForTestModalVisible(true);
    }


    const handleSentTestModalOk = () => {
        // console.log(sendForTestIntentData);
        // return;
        console.log('sendForTestIntentData.id', sendForTestIntentData.id)
        request.get('nlu/sync/utterances_status?utterances_id='+sendForTestIntentData.id).then((res)=>{
            console.log(res)
            // let data = res.data.results;
            // let data = apiIntentData;
            // if(props){
            //     let selectedIntent =  data.length > 0 && data.filter((v,i)=> props.match.params.id == v.id);
            //     // setIntentSelect(selectedIntent[0].name);
            //     getAlltrainData({data: {intent_id: selectedIntent[0].id}, page: 1});
            //     setIsSendForTestModalVisible(false);

            //     // console.log('asddad');
            // }else{
            //     // setIntentSelect(undefined);
            //     getAlltrainData({data: undefined, page: 1});
            //     setIsSendForTestModalVisible(false);
            // }
            // setHistoryData(data);
            // console.log(data);
            // getTheTableData();
        }).catch(err=>console.log(err))
            .finally(() => {
                setIsSendForTestModalVisible(false)
            })

        request.get(`nlu/confidence_score/${sendForTestIntentData.id}/`).then(res=>{
            console.log(res);
            if (res.data === "successfully update the scores"){
                NotificationInfo("Information", "successfully update the scores", "topRight");
                getTheTableData();
            }

        })
            .catch(err => console.log(err))
            .finally(() => {
                // getTheTableData();
                setIsSendForTestModalVisible(false)

            })
    }

    const handleSentTrainModalOk = () => {
        try{

            request.get(`nlu/intent_sync/${sendForTrainIntentData.id}`)
                .then(res=>{

                    request.get(`nlu/sync/utterances/${sendForTrainIntentData.id}/`)
                        .then((res)=>{
                            NotificationInfo("Information", "Sent to training!", "topRight");
                            setIsSendForTrainModalVisible(false);
                            getTheTableData();
                        })
                })
        }catch(err){
            console.log(err);
        }
    }

    const getTheTableData = (param) =>{
        let data = apiIntentData;
        if(props){
            let selectedIntent;
            let tableFilterData = !searchFilterValues ? {} : searchFilterValues;
            console.log('tableFilterDatannnnnnnnnnn', tableFilterData)
            getAlltrainData({data: tableFilterData, page: 1})

            return;

            if(intentSelectId){
                selectedIntent = [{id: intentSelectId}];
            }else{
                selectedIntent =  data.length > 0 && data.filter((v,i)=> props.match.params.id == v.id);
            }

            tableFilterData.intent_id = selectedIntent[0].id;


            // setIntentSelect(selectedIntent[0].name);
            getAlltrainData({data: tableFilterData, page: 1});
            setIsSendForTestModalVisible(false);
            setIsSendForRetrainModalVisible(false);
            setCurrUtterance(undefined);
            setIsDeleteModalVisible(false);
            if(param && param.modal_close){
                setIsModalVisible(false);
            }
            // console.log('asddad');
        }else{
            // setIntentSelect(undefined);
            getAlltrainData({data: undefined, page: 1});
            setIsSendForTestModalVisible(false);
            setIsSendForRetrainModalVisible(false);
            setCurrUtterance(undefined);
            setIsDeleteModalVisible(false);
            if(param.modal_close){
                setIsModalVisible(false);
            }
        }
    }

    const handleSentRetrainModalOk = () => {
        try{
            request.get(`nlu/intent_sync/${sendForRetrainIntentData.id}/`)
                .then(res=>{

                    request.get(`nlu/sync/utterances/${sendForRetrainIntentData.id}/`)
                        .then((res)=>{
                            NotificationInfo("Information", "Sent to training!", "topRight");
                            setIsSendForRetrainModalVisible(false);
                            getTheTableData();
                        })
                })
        }catch(err){
            console.log(err);
        }
    }

    const handleSentTestModalCancel = () =>{
        setIsSendForTestModalVisible(false);
    }

    const handleSentTrainModalCancel = () =>{
        setIsSendForTrainModalVisible(false);
    }


    const handleSentRetrainModalCancel = () =>{
        setIsSendForRetrainModalVisible(false);
    }

    const showAttachModal = (data) => {
        console.log(data)
        setShowSelectUtterance(data);

        if(data){
            setIsAttachModalVisible(true);
        }else {
            NotificationError('', 'Please select item', 'topRight');
        }
    };

    const addToIntentHandler = () => {
        setAttacheBtnToggle(true);
        // let promise = showSelectUtterance.map((v, i) => postUtterances(v.utterances, selectedIntentId));
        // Promise.all(promise).then((res)=>{
        //     setShowSelectUtterance(undefined);
        // }).catch(err=>console.log(err));
        let selectedUtteranceData = showSelectUtterance;
        selectedUtteranceData.status = "pending";
        console.log('selectedUtteranceData', selectedUtteranceData)

        postUtterances(selectedUtteranceData, attachIntentSelectId).then((res)=> {
            // let data = apiIntentData;
            console.log('data', selectedUtteranceData);
            request.get(`nlu/intent_sync/${selectedUtteranceData.id}/`)
                .then(res=>{
                    NotificationInfo('', 'successfully added', 'topRight');

                    request.get(`nlu/sync/utterances/${selectedUtteranceData.id}/`)
                        .then((res)=>{
                            NotificationInfo("Information", "Sent to training!", "topRight");

                            getTheTableData();
                        })
                })

            // if(props){
            //     let selectedIntent =  data.length > 0 && data.filter((v,i)=> props.match.params.id == v.id);
            //     // setIntentSelect(selectedIntent[0].name);
            //     getAlltrainData({data: {intent_id: selectedIntent[0].id}, page: 1});
            //     setIsAttachModalVisible(false);

            //     // console.log('asddad');
            // }else{
            //     // setIntentSelect(undefined);
            //     getAlltrainData({data: undefined, page: 1});
            //     setIsAttachModalVisible(false);
            // }
        })



    }

    const showModal = (data) => {
        console.log(historyData);
        setCurrIntent(data);
        setHistoryData(undefined);
        setIsModalVisible(true);
        setEditUtterModalReset(!editUtterModalReset);
    };

    const showDeleteModal = (data) => {
        setCurrUtterance(data);
        console.log(data);
        setIsDeleteModalVisible(true);
    };

    const handleDeleteModalOk = () => {
        setIntentTestText(undefined);

        request.delete(`nlu/utterances/${currUtterance.id}`)
            .then(res=>{
                getTheTableData();

            }).catch((error)=>{

        });
    };

    const handleCancel = () => {
        setIntentTestText(undefined);
        setCurrIntent(undefined);
        setHistoryData(undefined);
        setIsModalVisible(false);
        setEditUtterModalReset(true);
    };


    const handleDeleteModalCancel = () => {
        setIntentTestText(undefined);
        setCurrUtterance(undefined);
        setIsDeleteModalVisible(false);
    };

    const intentFilter = (intentSelectId) => {
        // console.log('sdfsdfsdfsdID', intentSelectId)
        apiUtterancesData.filter((value) => {
            console.log(value.intent.id, intentSelectId)
        })
    }


    const handleAttachModalOk = () => {
        setIsAttachModalVisible(false);
    };

    const handleAttachModalCancel = () => {
        setIsAttachModalVisible(false);
    };

    const intentHandler = (v, option) => {
        setIntentSelect(option.children);
        setIntentSelectId(v);
    }
    const attachIntentHandler = (v, option) => {
        setAttachIntentValue(option.children);
        setAttachIntentSelectId(v);
    }


    useEffect(() => {
        setHistoryData(undefined);

        if (props){
            setIntentSelectId(props.match.params.id);
        }
        request.get('nlu/intent/').then(res => {
            let data = res.data.results;
            setApiIntentData(data);

            if(props){
                let selectedIntent =  data.length > 0 && data.filter((v,i)=> props.match.params.id == v.id);
                if(selectedIntent.length > 0){
                    setIntentSelect(selectedIntent[0].name);
                    getAlltrainData({data: {intent_id: selectedIntent[0].id}, page: 1});

                    console.log('asddad');
                }
            }else{
                setIntentSelect(undefined);
                getAlltrainData({data: undefined, page: 1});

            }

        })

    }, [])
    // console.log('apiUtterancesData', apiUtterancesData)
    // console.log('setApiIntentData', apiIntentData)
    // console.log('intentSelect', intentSelectId);

    let scoreSliderChangeAfter = (start, end)=>{
        setSliderStart(start);
        setSliderEnd(end);
        console.log(start, end);
    }

    let statusOnChange = (val)=>{
        setStatus(val);
    }

    let getAlltrainData = ({data, page}) =>{
        // console.log(data.intent_id);
        console.log(data);
        // alert('hi')

        if(!data){
            request.get(`nlu/utterances/`).then(res => {
                let data = res.data.results;
                setApiUtterancesData(data);
                setTotalUtterances(res.data['count']);
            })
        }else{
            let params = new URLSearchParams(data).toString();
            // alert(params);

            request.get('nlu/retrain?'+params+'&page='+page).then(res => {
                // alert('hi2')
                let data = res.data.results;
                setApiUtterancesData(data);
                // console.log(res.data['count']);
                // console.log(data);
                setTotalUtterances(res.data['count']);
            })
        }

    }

    let searchFilterFormSubmit = (event) => {
        event.preventDefault();
        // console.log(event.target);
        // event.target.map((v,i)=>{
        //     console.log(v);
        // })
        // for(let i=0; i < event.target.length; i++){
        //     console.log(event.target[i].getAttribute('name'), event.target[i].value);
        // }



        const data = {
            start: sliderStart /100,
            end: sliderEnd / 100,
        };

        console.log(data);

        if(intentSelectId){
            data.intent_id = intentSelectId;
        }

        if(status){
            console.log(status);
            data.status = status;
        }

        setSearchFilterValues(data);

        // console.log(data);
        getAlltrainData({data, page: 1});
    }

    const aiFlowList = [
        {
            id: 0,
            // aiImage: responseLogPNG,
            aiFlowName: 'All Utterances'
        },
        {
            id: 1,
            // aiImage: blukTrainPNG,
            aiFlowName: 'Bulk Training'
        },
        {
            id: 2,
            // aiImage: profanityFilterPNG,
            aiFlowName: 'Profanity Filter'
        }
    ]


    let handleUtteranceAddModalOk = (e)=>{
        setIsUtteranceAddModalVisible(false);
    }

    let handleUtteranceAddModalCancel = (e)=>{
        setIsUtteranceAddModalVisible(false);
    }

    let handleUtteranceTestModalOk = (e)=>{
        setIsTestUtteranceModalVisible(false);
        setTestData(undefined);
        setTestUtteranceVal('');
    }

    let handleUtteranceTestModalCancel = (e)=>{
        setIsTestUtteranceModalVisible(false);
        setTestData(undefined);
        setTestUtteranceVal('');
    }


    let postUtterances = async (utterances, intent_id)=>{
        console.log('utterances', utterances);
        // return;
        let data = {
            "id": utterances.id,
            "name" : utterances.name,
            "intnt_id" : Number(intent_id),
            "sentence" : utterances.sentence,
            "entities" : utterances.entities,
            "traits" : utterances.traits,
            "comment" : "",
            "status" : utterances.status,
            "weight":0
        }
        // myArr.push(obj);

        // setUplodingDone(false);

        return await request.post('nlu/utterances/', data)
    }
    // let getUtterances = (page)=>{
    //     request.get('api/nlu/intent_get_details?intent_id='+ curr_intent_id +'&page='+page, token)
    //         .then(res=>{
    //             setUtterancesOtherData({count: res.data.count});
    //             // console.log(res.data);
    //             setAllUtterances(res.data.results);
    //         }).catch(err=>{
    //         console.log(err);
    //     })
    // }

    let saveUtterance = (e)=>{
        try{

            postUtterances({
                id: 0,
                name: utterances,
                sentence: utterances,
                status: 'pending',
                entities: [],
                traits: []
            }, intentSelectId).then((res)=>{
                // console.log('###########',res);
                // getUtterances(1);
                NotificationInfo("Information", "New Utterance Added!", "topRight");
                setIsUtteranceAddModalVisible(false);
                // request.get('api/nlu/intent_sync', token)
                // .then(()=>{
                //     // console.log('sync')

                //     request.get(`api/nlu/sync/utterances/${res.data['id']}/`, token)
                //     .then((res)=>{
                //         NotificationInfo("Information", "Sent to training!", "topRight");
                //         setUtterances("");
                //         let data = apiIntentData;

                //         if(props){
                //             let selectedIntent =  data.length > 0 && data.filter((v,i)=> props.match.params.id == v.id);
                //             // setIntentSelect(selectedIntent[0].name);
                //             getAlltrainData({data: {intent_id: selectedIntent[0].id}, page: 1})
                //             setIsModalVisible(false);
                //             console.log('asddad');
                //         }else{
                //             // setIntentSelect(undefined);
                //             getAlltrainData({data: undefined, page: 1});
                //             setIsModalVisible(false);

                //         }
                //     })
                // })
                setUtterances("");

                let data = apiIntentData;

                getTheTableData();


            });

        }catch(err){
            console.log(err);
        }

    }
    let utterancesOnChange = (e)=>{
        setUtterances(e.target.value);
    }

    // let addNewUtterance = (e)=>{
    //     postUtterances(utterances).then((res)=>{
    //         setUtterances(undefined)
    //     })
    //     // request.get('api/nlu/message/'+intentTestText, token).then((res)=>{
    //     //     let data = res.data.intents;
    //     //     data = data.map((v,i)=>{
    //     //         let obj = v;
    //     //         v.confidence = Math.round(v.confidence * 100)+ '%';
    //     //         return obj;
    //     //     });
    //     //     setTestData(res.data.intents);
    //     //     // console.log(res);
    //     // }).catch(err=>console.log(err));
    // }

    let testIntent = (e)=>{
        setIsTestUtteranceModalVisible(true);
    }

    let testUtterancesOnChange = (e)=>{
        setTestUtteranceVal(e.target.value);
    }

    let testUtterance = (e)=>{
        e.preventDefault();

        request.post('nlu/message/',
            {"data": testUtteranceVal}).then((res)=>{

            // let data = res.data.intents;
            res.data.intents.map((v,i)=>{
                let obj = v;
                v.confidence = Math.round(v.confidence * 100)+ '%';
                return obj;
            });
            // res.data.intents = data;
            console.log([res.data]);
            setTestData([res.data]);
            // console.log(res);
        }).catch(err=>console.log(err));
    }

    console.log('utterancesOtherData', utterancesOtherData)
    console.log('allUtterances', allUtterances)


    return (
        <>
            <Header />
            <div className='main_agent'>
                <Layout className='layouts' style={{
                    padding: '0 15px 15px',
                    width: 'calc(100% - 100px)',
                    position: "fixed",
                    right: 0,
                    top: 85
                }}>
                    <div className='left_main_aiflow'>
                        <h4>AI FLOWS</h4>
                        <ul className='d-flex item-center linklist'>
                            <li>
                                <Link className={`${history.location.pathname === '/response-log/' ? 'active' : null}`} to="/response-log/" onClick={getAlltrainData}>All Utterances</Link>
                            </li>
                            <li>
                                <Link className={`${history.location.pathname === '/bulk-train/' ? 'active' : null}`} to="/bulk-train/">Bulk training</Link>
                            </li>
                            <li>
                                <Link className={`${history.location.pathname === '/profanity-filter/' ? 'active' : null}`} to="/profanity-filter/" onClick={getAlltrainData}>Profanity filter</Link>
                            </li>
                            <div className='_test_button'>
                                <button onClick={testIntent} type='button' className='btn'>Test Utterances</button>
                            </div>
                        </ul>
                    </div>
                    <div className={styles.main_response_log}>
                        <div className={styles.inner_response}>
                            <div className='d-flex item-center'>
                                <form onSubmit={searchFilterFormSubmit} className={styles.left}>
                                    <div className={styles.filter}>
                                        <div className={styles.intent}>
                                            <p>Intent</p>
                                            <div className={styles.intent_select}>
                                                <Select
                                                    showSearch
                                                    value={intentSelect}
                                                    style={{ width: "100%" }}
                                                    placeholder="Please Select"
                                                    optionFilterProp="children"
                                                    onChange={(v, option) => intentHandler(v, option)}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        apiIntentData && apiIntentData.map(value => (
                                                                <Option key={value.id}>{value.name}</Option>
                                                            )
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                        <div className={styles.mainScore}>
                                            <p>Score</p>
                                            <div className={styles.score}>
                                                <Slider onAfterChange={([start, end]) => {
                                                    scoreSliderChangeAfter(start, end)
                                                }} name={'score_range'} range={{ draggableTrack: true }} defaultValue={[sliderStart, sliderEnd]} />
                                            </div>
                                        </div>
                                        <div className={styles.mainStatus}>
                                            <p>Status</p>
                                            <div className={styles.status}>
                                                <Select
                                                    name={'status_selection'}
                                                    showSearch
                                                    onChange={statusOnChange}
                                                    style={{ width: "100%" }}
                                                    placeholder="Please Select"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option key='pending'>Pending</Option>
                                                    <Option key='trained'>Trained</Option>
                                                </Select>
                                            </div>
                                        </div>
                                        {/* <div className={styles.mainSearch}>
                                    <p>Search</p>
                                    <div className={styles.search}>
                                        <Input name={'search_box'} placeholder="Search Here..." />
                                    </div>
                                </div> */}

                                        <div className={styles.buttonFilter}>
                                            <button type='btn' className={styles.buttonFilterBtn} onClick={() => intentFilter(intentSelectId)}>Filter</button>
                                        </div>
                                    </div>
                                </form>
                                {intentSelect && <div className={styles.buttonFilter}>
                                    <button
                                        type='btn'
                                        className={styles.buttonFilterBtn + ' ' + styles.newUtter}
                                        onClick={() => setIsUtteranceAddModalVisible(true)}
                                    >Add New Utterance</button>
                                </div>}
                                {/* <button type='btn'
                            className={styles.buttonFilterBtn + ' ' + styles.newUtter}
                            onClick={() => setIsTestIntentModalVisible(true)}
                        >Test Utterances</button> */}
                            </div>

                            <div className={styles.dataTable}>
                                <CTable
                                    pagination = {
                                        {
                                            onChange: page => {
                                                let data = apiIntentData;

                                                console.log(data)


                                                if(props){

                                                    let selectedIntent =  data.length > 0 && data.filter((v,i)=> props.match.params.id == v.id);
                                                    // setIntentSelect(selectedIntent[0].name);
                                                    console.log(selectedIntent)
                                                    return;
                                                    getAlltrainData({data: {intent_id: selectedIntent[0].id}, page})
                                                    setIsModalVisible(false);
                                                    console.log('asddad');
                                                }else{
                                                    // setIntentSelect(undefined);
                                                    getAlltrainData({data: undefined, page});
                                                    setIsModalVisible(false);

                                                }
                                                // getAlltrainData({data: undefined, page});
                                                // console.log(page);
                                            },
                                            pageSize: 20,
                                            total: totalUtterances
                                        }
                                    }
                                    {...
                                        {
                                            bordered: true,
                                            columns: columns,
                                            data: apiUtterancesData,
                                        }
                                    }
                                />
                            </div>

                            <Modal okButtonProps={{ style: { display: 'none' } }} className={styles.modalWidth} title={currIntent && currIntent.sentence} visible={isModalVisible} onCancel={handleCancel}>
                                {/* {console.log(currIntent)} */}
                                {currIntent && <Utterances
                                    willreset={editUtterModalReset}
                                    // resetCB={(editUtterModalReset)=> setEditUtterModalReset(editUtterModalReset)}
                                    saveChangesCB={(data)=>{
                                        // let data = apiIntentData;
                                        // if(props){
                                        //     let selectedIntent =  data.length > 0 && data.filter((v,i)=> props.match.params.id == v.id);
                                        //     // setIntentSelect(selectedIntent[0].name);
                                        //     getAlltrainData({data: {intent_id: selectedIntent[0].id}, page: 1});
                                        //     // setIsModalVisible(false);

                                        //     console.log('asddad');
                                        // }else{
                                        //     // setIntentSelect(undefined);
                                        //     getAlltrainData({data: undefined, page: 1});
                                        //     // setIsModalVisible(false);

                                        // }
                                        getTheTableData(data);
                                    }} props={{val: currIntent, i:0, curr_intent_id: currIntent.intent.id}} />}
                            </Modal>

                            <CModal width={500} title="Delete" modalIsVisible={isDeleteModalVisible} handleOk={()=>{handleDeleteModalOk()}} handleCancel={()=>handleDeleteModalCancel()} innerComponent={
                                <>
                                    <div>Sure you want to delete this utterance?</div>
                                </>
                            }/>

                            <CModal width={500} title="Send for Testing" modalIsVisible={isSendForTestModalVisible} handleOk={()=>{handleSentTestModalOk()}} handleCancel={()=>handleSentTestModalCancel()} innerComponent={
                                <>
                                    <div>Sure you want to send this utternece for testing?</div>
                                </>
                            }/>


                            <CModal width={500} title="Send for Training" modalIsVisible={isSendForTrainModalVisible} handleOk={()=>{handleSentTrainModalOk()}} handleCancel={()=>handleSentTrainModalCancel()} innerComponent={
                                <>
                                    <div>Sure you want to send this utternece for Training?</div>
                                </>
                            }/>

                            <CModal width={500} title="Send for Retraining" modalIsVisible={isSendForRetrainModalVisible} handleOk={()=>{handleSentRetrainModalOk()}} handleCancel={()=>handleSentRetrainModalCancel()}innerComponent={
                                <>
                                    <div>Sure you want to send this utternece for Re-Training?</div>
                                </>
                            }/>

                            <Modal className='attachment' title="Attachment" visible={isAttachModalVisible} onOk={handleAttachModalOk} onCancel={handleAttachModalCancel}>
                                <div>
                                    <div className='d-flex item-center justify-center'>
                                        <div className='d-flex item-center space-between'>
                                            <div className='intent_select'>
                                                <Select
                                                    value={attachIntentValue ? attachIntentValue : 'Please select intent'}
                                                    className={`${attachIntentValue == undefined ? 'error_intent_select' : 'intent_select'}`}
                                                    defaultValue="lucy"
                                                    onChange={(v, option)=>attachIntentHandler(v, option)}
                                                    bordered={false}
                                                >
                                                    {apiIntentData && apiIntentData.map(item => (
                                                        <Option key={item.id}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className='attach_list_btn'>
                                            <ul>
                                                <li>
                                                    <button type='button' className='attach_btn' onClick={addToIntentHandler}>Add to intent</button>
                                                </li>
                                                {
                                                    attacheBtnToggle &&
                                                    <>
                                                        {/* <li>
                                                    <button type='button' className='attach_btn'>Schedule</button>
                                                </li>*/}
                                                        {/* <li>
                                                        <button type='button' className='attach_btn' onClick={goToViewIntent}>
                                                            Go to Intent
                                                        </button>
                                                    </li> */}
                                                    </>
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <CModal {...{width:1000, title: "Add Utterance",
                                innerComponent: <>
                                    <div className='utterance'>
                                        <div className='input_utterance d-flex item-center justify-center'>

                                            <input type='text'
                                                   onKeyUp={event => event.keyCode === 13 && event.target.value != "" && saveUtterance()}
                                                   value={utterances}
                                                   onChange={utterancesOnChange}
                                                   placeholder='Type your utterance'/>
                                            <div className='train_button'>
                                                <button onClick={saveUtterance} type='button' className='btn'>Add New Utterance</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                , modalIsVisible: isUtteranceAddModalVisible, handleOk: handleUtteranceAddModalOk, handleCancel: handleUtteranceAddModalCancel}} />

                            <CModal {...{width:1000, title: "Test Utterance",
                                innerComponent: <>
                                    <div className='utterance'>
                                        <div className='input_utterance d-flex item-center justify-center'>

                                            <input type='text'
                                                   onKeyUp={event => event.keyCode === 13 && event.target.value != "" && testUtterance(event)}
                                                   value={testUtteranceVal}
                                                   onChange={testUtterancesOnChange}
                                                   placeholder='Type your utterance'/>
                                            <div className='train_button'>
                                                <button onClick={testUtterance} type='button' className='btn'>Test Utterance</button>
                                            </div>
                                        </div>

                                        <br/>
                                        {testData && <CTable {...{columns: test_columns, bordered: true, pagination: false, data: testData, selection: false}} />}

                                    </div>
                                </>
                                , modalIsVisible: isTestUtteranceModalVisible, handleOk: handleUtteranceTestModalOk, handleCancel: handleUtteranceTestModalCancel }} />
                        </div>
                    </div>
                </Layout>
            </div>
            <SidebarStatic />

        </>
    );
}

function Utterances({props, saveChangesCB, willreset, resetCB}){
    const { val, i, curr_intent_id} = props;

    //  console.log('kier value?', val);

    const [parsers, setParsers] = useState([
        {
            id: 1,
            name: 'value',
        },
        {
            id: 2,
            name: 'regexp',
        },
    ]);
    const [parser, setParser] = useState(undefined);
    const [selectedParser, setSelectedParser] = useState(undefined);

    //for training data later use on
    const [selectedWords, setSelectedWords] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState("");
    const [selectedRoles, setSelectedRoles] = useState({});
    //for submission
    const [trainData, setTrainData] = useState([]);

    const [entities, setEntities] = useState(['Topic', 'Action', 'Context']);
    // const [entityOption, setEntityOption] = useState([]);

    const [entityName, setEntityName] = useState("");
    const [entity, setEntity] = useState(undefined);

    const [roles, setRoles] = useState(undefined);
    // const [roleOption, setRoleOption] = useState([]);

    const [roleName, setRoleName] = useState("");
    const [role, setRole] = useState(undefined);

    // let [trainKey, setTrainKey] = useState(0);

    let [trainedEntities, setTrainedEntities] = useState([]);
    let [trainedTraits, setTrainedTraits] = useState([]);
    const [historyData, setHistoryData] = useState(undefined);
    const [sentForTraining, setSentForTraining] = useState(false);
    const training_form = useRef();

    const {Option} = Select;

    console.group(val);

    useEffect(()=>{
        setTrainData(val.entities);
        setTrainedEntities(val.entities);
        setTrainedTraits(val.traits);

        setHistoryData(undefined);

        getEntities();
        // getRoles();
    }, [val, willreset]);

    console.log('willreset', willreset);
    // LayoutE


    let getEntities = ()=>{
        request.get('nlu/entities/?unique=true')
            .then(res=>{
                setEntities(res['data']['results']);
            }).catch(err=>{
            console.log(err);
        })
    }

    let getRoles = ()=>{
        request.get('nlu/intent_terms/')
            .then(res=>{
                console.log(res);
                setRoles(res['data']['results']);
            }).catch(err=>{
            console.log(err);
        })
    }


    let getFilteredRole = (val)=>{
        if(val){
            request.get('nlu/lookup/?name='+val).then((res)=>{
                console.log(res);
                setRoles(res['data']['results']);
            })
        }
    }

    let onParserDropDownChange = (value,option) => {
        setParser(value);
        setSelectedParser(option);
    };

    let onEntityNameChange = (event) => {
        setEntityName(event.target.value);
    };

    let onEntityDropDownChange = (value,option) => {
        console.log(value, option);
        setEntity(value);
        setSelectedEntities(option);

        let entityType = option.children;


        getFilteredRole(entityType);

        // if(entityType === 'action'){
        //     getFilteredRole(entityType);
        // }else{
        //     getRoles();
        // }

    };


    let onRoleNameChange = (event) => {
        setRoleName(event.target.value);
    };

    let onRoleDropDownChange = (value, option) => {
        setRole(value);
        setSelectedRoles(option);
    };



    let addEntity = () => {

        request.post('nlu/entities/',
            {
                "name": entityName,
                "role": "Action",
                "confidence_score": 65,
                "value": "",
                "position": [
                    {
                        "start": 0,
                        "end": 0
                    }
                ]
            })
            .then(res=>{
                getEntities();

                setEntityName('');
            }).catch(err=>{
            console.log(err);
        })

    };

    let addRole = () => {
        // let Roles = [...roles];
        // Roles.push(roleName);

        // setRoles(Roles);

        if(selectedEntities){
            let entityName = selectedEntities.children;


            request.post('nlu/intent_terms/',
                {
                    "term_type" : entityName,
                    "term_context" : "",
                    "term_value" : roleName,
                    "order" : 0
                })
                .then(res=>{
                    getFilteredRole(selectedEntities.children);

                    setRoleName('');
                }).catch(err=>{
                console.log(err);
            })

        }else{
            NotificationError('information', 'Please select an entity first!');
        }

    };


    const selectedWordValueChange = (value, index) => {
        let wordsArr = value;

        setSelectedWords(wordsArr);
    };


    const onIntentAddButtonClick = ()=>{
        let data = [...trainData];

        data.unshift({
            "uuid": shortUUID.generate(),
            "value": selectedWords.join(','),
            "parser": selectedParser,
            "name": selectedEntities['children'],
            "entity": selectedEntities['children'],
            "entity_id": selectedEntities['value'],
            "role": selectedRoles['children'],
            "role_id": selectedRoles['value'],
            "confidence_score": 0
        });

        setSelectedWords(undefined);
        setParser(undefined);
        setSelectedParser(undefined);
        setEntityName("");
        setEntity(undefined);
        setRoleName("");
        setRole(undefined);

        setTrainData(data);
        setTrainedEntities(data);
    }

    let tableRowDelete = (uuid, e) => {
        e.preventDefault();
        console.log(uuid, e);
        const data = trainData.filter(item => item.uuid !== uuid);
        setTrainData(data);
        setTrainedEntities(data);
    }


    const history_data =  [
        {
            "key": 1,
            "history_name": "Example Name",
            "history_value": "Example Value"
        }
    ];

    const columns = [
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: '12%',
            render: (_, record)=>{
                return <Input name={"status"} value={record.status} disabled={true} placeholder="" />
            }
        },
        // {
        //     title: 'Property response',
        //     key: 'property_response',
        //     dataIndex: 'property_response',
        //     width: '18%',
        //     render: (_, record)=>{
        //       return <Input name={"property_response"} value={record.property_response != '' && record.property_response.name} disabled={true} placeholder="" />
        //     }
        // },
        {
            title: 'Created at',
            key: 'created_at',
            dataIndex: 'created_at',
            width: '20%',
            render: (_, record)=>{
                return <Input name={"created_at"} value={moment(record.created_at).format('MMMM Do YY, HH:mm')} disabled={true} placeholder="" />
            }
        },
        {
            title: 'Requested at',
            key: 'request_at',
            dataIndex: 'request_at',
            width: '20%',
            render: (_, record)=>{
                return <Input name={"request_at"}
                              value={moment(record.request_at).format('MMMM Do YY, HH:mm')} disabled={true} placeholder="" />
            }
        },
        {
            title: 'Completed at',
            key: 'completed_at',
            dataIndex: 'completed_at',
            width: '20%',
            render: (_, record)=>{
                return <Input name={'completed_at'} value={moment(record.completed_at).format('MMMM Do YY, HH:mm')} disabled={true} placeholder="" />

            }
        },
    ];

    let saveChangesTrainingForm = (e)=>{
        e.preventDefault();
        let data = {
            'id': val.id,
            'name': val.name,
            'intnt_id': curr_intent_id,
            'sentence': val.sentence,
            'entities': trainedEntities,
            'traits': trainedTraits,
            "comment" : "test comment",
            "status" : val.status,
            "weight":5
        };

        // console.log(data);
        // return;

        request.post('nlu/utterances/', data).then((res)=>{
            console.log(res);
            NotificationInfo("Information", "Changes have been saved!", "topRight");

            ///////////////
            saveChangesCB({modal_close: false});


        }).catch(err=> console.log(err));

        // console.log(data);
    }

    let send_for_testing = (e, id)=>{
        request.get('nlu/sync/utterances_status?utterances_id='+id).then((res)=>{
            let data = res.data.results;



            request.get('nlu/confidence_score/'+id+'/').then(res=>{

                setHistoryData(data);
                saveChangesCB({modal_close: false});
                // let data = apiIntentData;
                // if(props){
                //     let selectedIntent =  data.length > 0 && data.filter((v,i)=> props.match.params.id == v.id);
                //     // setIntentSelect(selectedIntent[0].name);
                //     getAlltrainData({data: {intent_id: selectedIntent[0].id}, page: 1});
                //     setIsSendForTestModalVisible(false);

                //     // console.log('asddad');
                // }else{
                //     // setIntentSelect(undefined);
                //     getAlltrainData({data: undefined, page: 1});
                // }
            })

            // console.log(data);
        }).catch(err=>console.log(err));
    }


    let trainAndValidate = (e)=>{
        setSentForTraining(true);
        try{
            request.get(`nlu/intent_sync/${val.id}/`)
                .then(res=>{

                    request.get(`nlu/sync/utterances/${val.id}/`)
                        // request.get(`api/nlu/sync/utterances/`, token)
                        .then((res)=>{
                            NotificationInfo("Information", "Sent to training!", "topRight");
                            setSentForTraining(false);
                            saveChangesCB({modal_close: false});
                        })
                })
        }catch(err){
            console.log(err);
            NotificationInfo("Information", err, "topRight");
        }
    }

    return(
        <form style={{marginBottom: "10px"}} ref={training_form} onSubmit={saveChangesTrainingForm} name="training_data">
            {/* <h3></h3> */}

            <Row style={{marginBottom:16}}>
                <Col span={16}></Col>
                <Col span={8} style={{textAlign:'right'}}>
                    <button type='button' onClick={() => {
                        const form = training_form.current;
                        if (form) {
                            if (typeof form.requestSubmit === 'function') {
                                form.requestSubmit();
                            } else {
                                form.dispatchEvent(new Event('submit', {cancelable: true}));
                            }
                        }
                    }} className='btn add_trait_button'>Save Changes</button>
                </Col>
            </Row>

            {/* tab for entity match */}
            <div className='train_tab'>
                <Tabs tabPosition={'left'}>
                    <TabPane tab="Entity" key="1">
                        <div className='d-flex item-center space-between' style={{ margin: '0 0 10px 0', }}>
                            <div className='training_input mb-10'>
                                <Select
                                    style={{ width: '100%', margin: '0', border: '1px solid #d9d9d9', borderRadius: '5px' }}
                                    placeholder="Select words to train"
                                    mode="multiple"
                                    value={selectedWords}
                                    onChange={(v) => selectedWordValueChange(v)}

                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} />
                                                <a href
                                                   style={{ color: '#b46fe5', flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}

                                                >
                                                    <PlusOutlined /> Add item
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {val && val.sentence && val.sentence.split(' ').map(item => (
                                        <Option key={item} value={item}>{item}</Option>
                                    ))}
                                </Select>

                                <Select
                                    style={{ width: '50%' }}
                                    placeholder="Select Parser"
                                    value={parser}
                                    onChange={(v, option) => onParserDropDownChange(v, option)}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                        </div>
                                    )}
                                >
                                    {parsers.map(item => (
                                        <Option key={item['id']} value={item['id']}>{item['name']}</Option>
                                    ))}
                                </Select>

                                <Select
                                    style={{ width: '50%' }}
                                    placeholder="Select entity"
                                    value={entity}
                                    onChange={(v, option) => onEntityDropDownChange(v, option)}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'wrap', padding: 8 }}>
                                                <Input placeholder="Add entity" style={{ flex: 'auto' }} value={entityName} onChange={onEntityNameChange} />
                                                <a href
                                                   style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                   onClick={addEntity}
                                                >
                                                    <PlusOutlined /> Add item
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {entities.map(item => (
                                        <Option key={item['id']}>{item['name']}</Option>
                                    ))}
                                </Select>

                                <Select
                                    style={{ width: '50%' }}
                                    placeholder="Select role"
                                    value={role}
                                    onChange={(v, option) => onRoleDropDownChange(v, option)}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'wrap', padding: 8 }}>
                                                <Input placeholder="Add role" style={{ flex: 'auto' }} value={roleName} onChange={onRoleNameChange} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                   onClick={addRole} href>
                                                    <PlusOutlined /> Add item
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {console.log('roles',roles)}
                                    {roles && roles.map(item => (
                                        <Option key={item.id}>{item.term_value}</Option>
                                    ))}
                                </Select>

                                <button type='button' onClick={() => { onIntentAddButtonClick() }} className='btn add_trait_button'>Add</button>
                            </div>
                            {/* <button type='button' className='btn add_trait_button'>Add trait</button> */}
                        </div>



                        {/* only entity fields */}
                        {trainedEntities.length > 0 && <Entity trainData={trainData} table_row_delete={tableRowDelete} />}
                    </TabPane>
                    <TabPane tab="Trait" key="2">
                        <Trait trainedTraits={trainedTraits} trainedTraitsCB={(data)=>{setTrainedTraits(data)}} />
                    </TabPane>
                    <TabPane tab="Settings" key="3">
                        <Row style={{marginBottom:16}}>
                            <Col offset={12} span={12} style={{textAlign:'right'}}>
                                <Space>
                                    <button type='button' onClick={(e)=>{send_for_testing(e, val.id)}} className='btn add_trait_button'>Send for Testing</button>
                                </Space>
                                <Space>
                                    {sentForTraining ? <Button type="primary" className='btn add_trait_button' loading>
                                            Loading
                                        </Button> :
                                        <button type={"button"} onClick={trainAndValidate} className='btn add_trait_button' size={'medium'}>
                                            Train and Validate
                                        </button>}
                                </Space>
                            </Col>
                        </Row>

                        <CTable {...{pagination: false, columns:columns, data: historyData}} />
                    </TabPane>
                </Tabs>
            </div>
        </form>
    )
}


function Entity({trainData, table_row_delete}) {

    console.log(trainData);

    const columns = [
        {
            title: 'Entity',
            key: 'entity',
            dataIndex: 'entity',
            width: '20%',
            render: (_, record)=>{
                return <Input data-type={'entity'} data-has-val name={"entity"} value={record.entity} disabled={true} placeholder="history value" />
            }
        },
        {
            title: 'Role',
            key: 'role',
            dataIndex: 'role',
            width: '20%',
            render: (_, record)=>{
                return <Input data-type={'entity'} data-has-val name={'role'} value={record.role} disabled={true} placeholder="history name" />

            }
        },
        {
            title: 'Value',
            key: 'words',
            dataIndex: 'words',
            width: '30%',
            render: (_, record) => {
                // const editable = isEditing(record);
                // return
                return <Input data-type={'entity'} data-has-val name={"value"} value={record.value.split(',').join(' ')} disabled={true} placeholder="Topic" />

            },
        },
        {
            title: 'Parser',
            key: 'parser',
            dataIndex: 'parser',
            width: '15%',
            render: (_, record)=>{
                console.log(record);
                return <Input data-type={'entity'} data-has-val name={'parser'} data-parser-id={record.parser.value} value={record.parser.children} disabled={true} placeholder="Parser" />

            }
        },
        {
            title: 'Confidence',
            key: 'confidence',
            // dataIndex: 'words',
            width: '10%',
            render: (_, record) => {
                // const editable = isEditing(record);
                // return
                return <Input data-type={'entity'} data-has-val name={"confidence"} value={''} disabled={true} placeholder="" />

            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button onClick={(e)=>table_row_delete(record.uuid, e)} danger size="middle">
                    Delete
                </Button>
            ),
        },
    ];

    return(
        // entity table
        <div className='mb-10'>
            <CTable selectedRowsCB={(data)=>{console.log(data)}} {...{ bordered: true, selectRowKey: 'uuid', selection: false, pagination: false, columns, data: trainData, }}/>
            {/* <Table bordered pagination={false} columns={columns} dataSource={trainData}/> */}

            {/* <div className='value'>
                <Input name={"entity"} data-entity-id={trainData.entity_id} value={trainData.entity} disabled={true} placeholder="Topic" />
            </div>
            <div className='value'>
                <Input name={'role'} data-role-id={trainData.role_id} value={trainData.role} disabled={true} placeholder="Select Role" />
            </div>
            <div className='value'>
                <Input name={'value'} value={trainData.words} placeholder="value" disabled={true} />
            </div>
            <div className='confidence'>
                <Input disabled={true} name={'confidence'} placeholder="60%" />
            </div>

            <div className='close'>
                <div className='edit'>
                    <EditOutlined />
                </div>
                <div className='delete'>
                    <CloseOutlined />
                </div>
            </div> */}
        </div>
    );
}

function Trait({trainedTraits, trainedTraitsCB}) {
    const [traitName, setTraitName] = useState();
    const [traitValue, setTraitValue] = useState();
    const [traitData, setTraitData] = useState([]);
    let [traitKey, setTraitKey] = useState(0);

    let traitTableRowDelete = (uuid, e) => {
        e.preventDefault();
        console.log(uuid,e);
        const data = traitData.filter(item => item.uuid !== uuid);
        setTraitData(data);
        trainedTraitsCB(data);
        console.log(trainedTraitsCB(data))

    }


    const columns = [
        {
            title: 'Trait Name',
            key: 'trait_name',
            dataIndex: 'trait_name',
            width: '45%',
            render: (_, record)=>{
                return <Input data-type={'trait'} data-has-val name={'trait_name'} value={record.trait_name} disabled={true} placeholder="Select Role" />

            }
        },
        {
            title: 'Trait Value',
            key: 'trait_value',
            dataIndex: 'trait_value',
            width: '45%',
            render: (_, record)=>{
                return <Input data-type={'trait'} data-has-val name={"trait_value"} value={record.trait_value} disabled={true} placeholder="Topic" />
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button onClick={(e)=>traitTableRowDelete(record.uuid, e)} danger size="middle">
                    Delete
                </Button>
            ),
        },
    ];

    useEffect(()=>{
        if(trainedTraits){
            setTraitData(trainedTraits);
        }
    })

    const onTraitAddButtonClick = (e)=>{
        e.preventDefault();

        let data = [...traitData];
        let key = traitKey;
        key++;
        setTraitKey(key);

        data.unshift({
            "uuid": shortUUID.generate(),
            "term_type": "trait",
            "term_context": traitName,
            "term_value": traitValue,
            "trait_name": traitName,
            "trait_value": traitValue,
            "order": 0
        });

        setTraitName(undefined);
        setTraitValue(undefined);

        setTraitData(data);
        trainedTraitsCB(data);
    }



    return (
        <>
            <Row className={'mb-10'} gutter={16}>
                <Col md={20}>
                    <Row gutter={16}>
                        <Col md={11}>
                            <Input name={"entity"} onChange={(e) => { setTraitName(e.target.value) }} value={traitName} placeholder="Trait Name" />
                        </Col>
                        <Col md={11}>
                            <Input name={'role'} onChange={(e) => { setTraitValue(e.target.value) }} value={traitValue} placeholder="Trait Value" />
                        </Col>
                        <Col md={2}>
                            <Button type={'primary'} className='btn' onClick={(e)=>{onTraitAddButtonClick(e)}}><PlusOutlined/>Add Another</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {traitData.length > 0
            &&
            <Row gutter={16}>
                <Col md={24}>
                    <CTable {...{bordered: true, pagination: false, columns:columns, selection: false, data: traitData}} />
                </Col>
            </Row>}
        </>
    );
}


export default ResponseLog;
