import React, {Component, ReactNode} from "react";
import {
    Redirect
} from 'react-router-dom';
import { connect } from "react-redux";


class RequireAuth extends Component <any, any> {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    
    render(){
        if (this.props.token) {
            return this.props.children;
        }
        return <Redirect to={'/Login'} />;
    }
};

const mapStateToProps = (state) => ({
    token: state.auth.currentUserToken,
});

export default connect(mapStateToProps, null)(RequireAuth);