import React from "react";
import {Modal} from 'antd';
import { useState, useEffect } from "react";

export default function CModal({width, title, innerComponent, modalIsVisible, handleOk, handleCancel}){
    const [isModalVisible, setIsModalVisible] = useState();

    useEffect(()=>{
        setIsModalVisible(modalIsVisible);
    }, [modalIsVisible]);

    return(   
        <Modal width={width} title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            {innerComponent} 
        </Modal> 
    );
}

