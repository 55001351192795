import axios from "axios";

export const request = {
    get: (url) =>{
        // let token = JSON.parse(JSON.parse(window.sessionStorage.getItem('persist:root'))['loginLogoutReducer'])['token'];

        return axios.get(`${process.env.REACT_APP_API_URL}${url}`);
        

        // return axios.get(`${process.env.REACT_APP_API_URL}${url}`, { headers: { Authorization: `Bearer ${token}` } });
    },
    post: (url, data)=>{
        return axios.post(`${process.env.REACT_APP_API_URL}${url}`, data);
    },
    put: (url, data)=>{
        return axios.put(`${process.env.REACT_APP_API_URL}${url}`, data);
    },
    patch: (url, data)=>{
        return axios.patch(`${process.env.REACT_APP_API_URL}${url}`, data);
    },
    delete: (url, headers) =>{
        return axios.delete(`${process.env.REACT_APP_API_URL}${url}/`);
    },
    fileupload: (url, data, uploadProgress) =>{
        return axios.post(`${process.env.REACT_APP_API_URL}${url}/`, data, {

            onUploadProgress: function(progressEvent) {
                var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );

                uploadProgress(percentCompleted);
            },
        })
    }
}
