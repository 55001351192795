import React, { Component } from "react"
import { connect } from "react-redux";

interface Props {
    item: any,
    addOrUpdateFlow: any,
    allItems: any,
}

class Audio extends Component <Props, {}> {
    constructor(props: Props){
        super(props)

    }
    
    render() {
        return (
            <React.Fragment>
                <div className="move box_style audio append_box audio_animation" id={"audio"+this.props.item.id} data-toggle="modal" data-target={"#audio_modal"+this.props.item.id}>
                    {/*<input type="file" id="audio_upload" />*/}
                    <br />
                    <img src="assets/audio.png" />
                    {/*<i className="fa fa-volume-off" aria-hidden="true"></i>*/}
                    <p>+Audio <span id="audio_file_name">:</span></p>
                    <div className="delet_icon_gallery" id="gallery_delete" onClick={() => this.props.addOrUpdateFlow(this.props.allItems.filter(item => item.id !== this.props.item.id))}>
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </div>
                </div>

                <div className="modal fade" id={"audio_modal"+this.props.item.id}  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Upload video
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <input type="text" className="form-control modal_input" placeholder="Enter Your Audio Url" />
                                </div>
                                <p className="or">OR</p>
                                <div className="video_upload_file">
                                    <input type="file" className="Modal_fileuploader" />
                                    <img src="assets/upload.png"/>
                                    <p>Upload File</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Delete</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    allItems: state.automation.items,
});

export default connect(mapStateToProps, null)(Audio);
