import React, { Component } from "react";
import { Doughnut } from 'react-chartjs-2';


interface Props {
  chart: any
}



export default class DoughnutChart extends Component<Props, {}> {
  data = () => {
    return  {
      labels: [
        'Volume Packs',
        'Social Packs',
        'Bundle Packs'
      ],
      datasets: [{
        data: [300, 50, 100],
        backgroundColor: [
        '#FF6384',
        '#36A2EB',
        '#FFCE56'
        ],
        hoverBackgroundColor: [
        '#FF6384',
        '#36A2EB',
        '#FFCE56'
        ]
      }]
    };;
  }

  render() {
    const options = {
      legend: {
        display: true,
        position: 'bottom'
      },
    };
    return (
      <div>
        <Doughnut
          height={120} data={this.data()} options={options} />
      </div>
    );
  }
};