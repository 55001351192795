import React from 'react';
import {FolderFilled, RightOutlined} from "@ant-design/icons";
import { Table, Button, Select, Row, Col, Modal, Layout, Input } from 'antd';
import './Train-css/Train.css'
import {Link} from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import {request} from '../../../api_request'
import {Header} from '../../header/Header'

import { connect } from 'react-redux';
import {logoutUser} from "../../login/actions/authActions";
import Sidebar from "../../sidebar/Sidebar";
import SidebarStatic from "../../sidebar-static";
const { Option } = Select;

function ConnectedIntents() {
    const [isAddGroupModalVisible, setIsAddGroupModalVisible] = useState(false);
    const [isAddIntentModalVisible, setIsAddIntentModalVisible] = useState(false);
    const [newGroupName, setNewGroupName] = useState(undefined);
    const [newIntentName, setNewIntentName] = useState(undefined);
    const [groups, setGroups] = useState([]);
    const [currGroupId, setCurrGroupId] = useState(undefined);
    const [currentGroupIntents, setCurrentGroupIntents] = useState([]);
    const [showFolderName, setShowFolderName] = useState();


    const columns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            // render: (_, record) => <Link to={'/intents/'+record.id+'/train/'}><Button type={"primary"}>Utterances</Button></Link>,
            render: (_, record) => <Link to={'/intents/'+record.id+'/response-log/'}><Button type={"primary"}>Utterances</Button></Link>,

        },
    ];

    // const data = [
    //     {
    //         key: 1,
    //         name: 'Intent Name',
    //     }
    // ];

    const showGroupModal = () => {
        setIsAddGroupModalVisible(true);
    };
    const showIntentModal = () => {
        setIsAddIntentModalVisible(true);
    };

    const handleGroupModalOk = () => {
        request.post('nlu/intent_terms/',
            {
                "term_type" : "GROUP",
                "term_context" : "",
                "term_value" : newGroupName,
                "order" : 0
            })
            .then(res => {
                getAllGroups();
                setIsAddGroupModalVisible(false);
            }).catch(err => {
                console.log(err);
            })
    };

    const handleIntentModalOk = () => {
        request.post('nlu/intent/',
            {
                "name": newIntentName,
                "short_code": newIntentName.replace(/ /g,"_").toLowerCase(),
                "intent_terms_id": currGroupId,
                "marked": "Favourite"
            })
            .then(res => {
                getCurrentGroupIntents(currGroupId);
                setIsAddIntentModalVisible(false);
            }).catch(err => {
                console.log(err);
            })
    };

    const handleGroupModalCancel = () => {
        setIsAddGroupModalVisible(false);
    };

    const handleIntentModalCancel = () => {
        setIsAddIntentModalVisible(false);
    };
    

    function handleChange(value) {
        console.log(`selected ${value}`);
    }

    useEffect(()=>{
        getAllGroups();

    }, [])

    const getAllGroups = ()=>{
        request.get('nlu/lookup/?name=GROUP'
        ).then((res)=>{
            setGroups(res.data.results);
        }).catch((err)=>{
            console.log(err);
        })
    }

    const getCurrentGroupIntents = (id)=>{
        request.get(`nlu/intent_group/?id=${id}`)
        .then(res=>{
            // console.log(res);
            setCurrentGroupIntents(res.data.results);
        }).catch(err=>{
            console.log(err);
        });
    }

    const changeNewGroupName = (e)=>{
        setNewGroupName(e.target.value);
    }
    const changeNewIntentName = (e)=>{
        setNewIntentName(e.target.value);
    }

    const onClickFolder = (e, id)=>{
        setShowFolderName(e.target.outerText);
        setCurrGroupId(id);

        getCurrentGroupIntents(id);
    }
    console.log('sdfsdf', groups.length)
    return(
        <>
            <Header />
            <div className='d-flexs'>
                <div className='intent_custome_cols'>
                    <Layout className='layouts' style={{
                        padding: '0 15px 15px',
                        width: '100%',
                        paddingRight: '0px'
                    }}>
                        <Modal title="Add New Intent" visible={isAddIntentModalVisible} onOk={handleIntentModalOk} onCancel={handleIntentModalCancel}>
                            <Input onChange={changeNewIntentName} value={newIntentName} name={"intent_name"} placeholder={"Enter new intent name"} />
                        </Modal>

                        <Modal title="Add New Group" visible={isAddGroupModalVisible} onOk={handleGroupModalOk} onCancel={handleGroupModalCancel}>
                            <Input onChange={changeNewGroupName} value={newGroupName} name={"group_name"} placeholder={"Enter new group name"} />
                        </Modal>

                        <div className='intents_section ___intents_section'>
                            <div className='intent_right_header'>
                                <div className='right_header_title'>
                                    <h1>Utterance Management</h1>
                                </div>
                                <div className='right_header_button '>
                                    {
                                        currGroupId != undefined &&
                                        <>
                                            <Button onClick={(e)=>{showIntentModal()}} type='primary' className='btn'>Create Intent</Button>
                                        </>
                                    }
                                </div>
                            </div>

                            <div className='d-flex'>
                                <div className='intent_left_intent'>
                                    <Row style={{marginBottom: '15px'}}>
                                        <Col md={12} style={{fontSize: '18px', fontWeight: 'bold'}}>Groups</Col>
                                        <Col md={12} style={{textAlign: 'right'}}><Button onClick={()=>showGroupModal()} className={'btn'} type={'primary'}>Add Group</Button></Col>
                                    </Row>
                                    {groups.length > 0 &&

                                    <ul style={{marginBottom: '20px', padding: '10px 0'}} className={`${groups.length > 6 ? 'group_scroll': ''}`}>
                                        {groups.map((v,i)=>{
                                            return <>
                                                <li key={v.id} className={`${v.id === currGroupId ? 'active': null}`} onClick={(e)=>{onClickFolder(e, v.id)}}>
                                                    <FolderFilled />
                                                    <p>{v.term_value}</p>
                                                </li>
                                            </>
                                        })}
                                    </ul>
                                    }
                                </div>
                                <div className='intent_right_intent'>
                                    <div className='d-flex item-center space-between mt-20'>
                                        <div className='folder_name'>
                                            <h1>{showFolderName ? showFolderName.toString().toUpperCase() : null}</h1>
                                        </div>
                                        <div className='d-flex item-center justify-center'>
                                            <div className='intent_search_area'>
                                                <input type='text' placeholder='Search here...' />
                                            </div>
                                            <div className='filter'>
                                                <Select defaultValue="Please Select" style={{ width: 130 }} onChange={handleChange}>
                                                    <Option value="Frequency">Frequency</Option>
                                                    <Option value="Reset">Reset</Option>
                                                    <Option value="Oldest">Oldest</Option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='intent_table'>
                                        {currentGroupIntents && <Table columns={columns} dataSource={currentGroupIntents}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Layout>
                </div>
            </div>
            <SidebarStatic />

        </>
    );
}


export default ConnectedIntents;
