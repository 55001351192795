import React from "react";
import {Table} from 'antd';
import { useState, useRef, useEffect } from "react";

export default function CTable({columns, data, pagination, selection, bordered, selectRowKey, selectedRowsCB}){
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    let onSelectChange = (selectedRowKeys, selectdRows) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        // console.log('selectedRows changed: ', );
        selectedRowsCB(selectdRows);
        // console.log('selectedRowKeys: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    // const columns = Object.keys(data[0]).map((v,i)=>{
    //     return {
    //               title: v.split('_').join(' '),
    //               key: v,
    //               dataIndex: v,
    //               render: (_, record)=> ReactHtmlParser(`<Input data-type={${v}} data-has-val name={${v}} value=${record[v]} disabled={true} placeholder="Select Role" />`)
    //             }
    //     });

    // const columns = [
    //     {
    //       title: 'Trait Name',
    //       key: 'trait_name',
    //       dataIndex: 'trait_name',
    //       width: '45%',
    //       render: (_, record)=>{
    //         return <Input data-type={'trait'} data-has-val name={'trait_name'} value={record.trait_name} disabled={true} placeholder="Select Role" />
            
    //       }
    //     },
    //     {
    //       title: 'Trait Value',
    //       key: 'trait_value',
    //       dataIndex: 'trait_value',
    //       width: '45%',
    //       render: (_, record)=>{
    //         return <Input data-type={'trait'} data-has-val name={"trait_value"} value={record.trait_value} disabled={true} placeholder="Topic" />
    //       }
    //     },
    //     {
    //         title: 'Action',
    //         key: 'action',
    //         render: (_, record) => (
    //           <Button onClick={(e)=>traitTableRowDelete(record.uuid, e)} danger size="middle">
    //             Delete
    //           </Button>
    //         ),
    //     },
    // ];

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows)=>onSelectChange(selectedRowKeys, selectedRows),
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: 'odd',
                text: 'Select Odd Row',
                onSelect: changableRowKeys => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                        if (index % 2 !== 0) {
                            return false;
                        }
                        return true;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
            {
                key: 'even',
                text: 'Select Even Row',
                onSelect: changableRowKeys => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                        if (index % 2 !== 0) {
                            return true;
                        }
                        return false;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
        ],
    };
    useEffect(()=>{
        
    }, [data]);

    return(   
        <Table bordered={bordered} columns={columns} rowSelection={selection ? rowSelection : selection} pagination={pagination} dataSource={data} rowKey={selectRowKey} /> 
    );
}

