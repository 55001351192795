
import React, {useEffect, useState} from 'react'
import {Table, Modal,Spin, Space, Input, Row, Col, Select, Divider} from 'antd';
import './ai-flow-css/bulk.css'
import {Link, useHistory} from "react-router-dom";
import {request} from "../../../api_request";
import {connect} from "react-redux";
import CTable from "../global/table";
import {NotificationError, NotificationInfo} from "../global/DisplayNotification";
import Layout from "antd/es/layout/layout";
import CModal from "../global/modal";
import {Header} from '../../header/Header'
import SidebarStatic from "../../sidebar-static";
const { Option } = Select;


function BulkRetrain(){
    const [isAttachModalVisible, setIsAttachModalVisible] = useState(false);
    const [attacheBtnToggle, setAttacheBtnToggle] = useState(false);
    const [allUtterances, setAllUtterances] = useState(undefined);
    const [utteranceAttach, setUtteranceAttach] = useState([]);
    const [showSelectUtterance, setShowSelectUtterance] = useState();
    const [showAllIntent, setShowAllIntent] = useState(undefined);
    const [intentValue, setIntentValue] = useState(undefined);
    const [selectedIntentId, setSelectedIntentId] = useState(undefined);
    const [totalUtterances, setTotalUtterances] = useState(undefined);
    const [isTestUtteranceModalVisible, setIsTestUtteranceModalVisible] = useState(false);
    const [utterances, setUtterances] = useState(undefined);
    const [testUtteranceVal, setTestUtteranceVal] = useState(undefined);
    const [testData, setTestData] = useState();
     const [preload, setPreload] = useState(false);
     const [successfullyAdded, setSuccessfullyAdded] = useState(false);
     const [isBulkUploadModalVisible, setIsBulkUploadModalVisible] = useState(false);
    const [currentIntentID, setCurrentIntentID]= useState(undefined)
    const [utterancesOtherData, setUtterancesOtherData] = useState(undefined);
    const [uploadingDone, setUplodingDone] = useState(true);
    const [bulkUtteranceID, setBulkUtteranceID] = useState();


    const showAttachModal = () => {
        let attachUtter = [];
        attachUtter.push(utteranceAttach);
        setShowSelectUtterance(attachUtter[0]);

        if(attachUtter[0].length > 0){
            setIsAttachModalVisible(true);
        }else {
            NotificationError('', 'Please select item', 'topRight');
        }
    };
    const handleOk = () => {
        setIsAttachModalVisible(false);
    };

    const handleCancel = () => {
        setIsAttachModalVisible(false);
    };

    const columns = [
        {
            key: 'id',
            dataIndex: 'utterances'
        }
    ];

    const history = useHistory();

    const goToViewIntent = () => {
        history.push(`/intents/${selectedIntentId}/response-log`);
    }

    // const goToIntentOnBulkImport = () => {
    //     history.push(`/intents/${selectedIntentId}/response-log`);
    // }


    const fileUploadHandler = event =>{
        event.preventDefault();
        let form = new FormData();
        form.append('file', event.target.files[0]);
        if(currentIntentID){

            form.append('intent_id', currentIntentID);
            // console.log(form);
            try{

                setUplodingDone(false);
    
                request.post('nlu/import/', form).then(res=>{
                    // console.log(res);
                    request.post(`nlu/file_utterances/${currentIntentID}/intent_utterances/`, {})
                    .then((res)=>{
                         console.log('utttt',res);
                        let results = res.data.results;
    
                        setUplodingDone(true);
                        console.log('setUplodingDone',true);
    
                        if(results !== undefined){
                            console.log('results', results);
    
                                
                            
                            let promise = results.map((v,i) =>{
                                postUtterances(v.utterances, v.intent)
                                console.log('sdfsdf', v)
                            });
                        
                            Promise.all(promise).then((res)=>{
                                console.log(res);
                                // getUtterances(1);
                            }).catch(err=>console.log(err));
                        }
                        
                    })
                })
            }
            catch(err){
                console.log(err);
            }
        }else{
            request.post('nlu/import/', form).then(res => {
                setPreload(true)
                if (res.data = "successfully created"){
                    setSuccessfullyAdded(res.data);
                    getAllUtternces(1);
                    setTimeout(() => {
                        setPreload(false);
                        NotificationInfo('', 'successfully added', 'topRight');
                    },1000)
                }
            })
        }

        


        let getUtterances = (page)=>{
            request.get('nlu/intent_get_details?intent_id='+currentIntentID+'&page='+page)
            .then(res=>{
                setUtterancesOtherData({count: res.data.count});
                // console.log(res.data);
                setAllUtterances(res.data.results);
            }).catch(err=>{
                console.log(err);
            })
        }

        // let promise = postUtterances(utterances, selectedIntentId);
        // Promise.all(promise).then((res)=>{
        //     setShowSelectUtterance(undefined);
        //     request.get('api/nlu/intent_sync/',)
        //     .then(()=>{
        //         let promise = res.map((v,i)=> request.get('api/nlu/sync/utterances/',));
        //         Promise.all(promise).then((res)=>{
        //             NotificationInfo('information', 'Atttached and sent to training!')
        //         });
        //     })

        // }).catch(err=>console.log(err));
        // NotificationInfo('', 'successfully added', 'topRight')
    
    }

    useEffect(()=>{
        getAllUtternces(1);
        getAllIntents();
    },[])


    let postUtterances = async (utterances, intentId)=>{
        let data = {
            "id": 0,
            "name" : utterances,
            "intnt_id" : intentId,
            "sentence" : utterances,
            "entities" : [
            ],
            "traits" : [
            ],
            "comment" : "test comment",
            "status" : "pending",
            "weight":0
        }

        return await request.post('nlu/utterances/', data)
    }

    const intentHandler = (v, option) => {
        setCurrentIntentID(v)
        setSelectedIntentId(v)
        setIntentValue(option.children);
    }

    const addToIntentHandler = () => {
        if (intentValue == undefined){
            NotificationError('', 'Please select intent', 'topRight');
        }else {
            setAttacheBtnToggle(true);
            let promise = showSelectUtterance.map((v, i) => postUtterances(v.utterances, selectedIntentId));
            Promise.all(promise).then((res)=>{
                setShowSelectUtterance(undefined);
            }).catch(err=>console.log(err));
            NotificationInfo('', 'successfully added', 'topRight')
        }

    }

    let attachAndTrain = () => {
        if (intentValue == undefined){
            NotificationError('', 'Please select intent', 'topRight');
        }else {
            setAttacheBtnToggle(true);
            let promise = showSelectUtterance.map((v, i) => {
                console.log(v.id)
                console.log(v)
                 setBulkUtteranceID(v.id);
                postUtterances(v.utterances, selectedIntentId)
            });

            console.log('bulkUtteranceID', bulkUtteranceID)

            Promise.all(promise).then((res)=>{
                console.log('##############',res)
                setShowSelectUtterance(undefined);

                // console.log(res);

                request.get('nlu/intent_sync/')
                .then(()=>{

                    let promise = res.map((v,i)=> request.get('nlu/sync/utterances/'));

                    Promise.all(promise).then((res)=>{
                        NotificationInfo('information', 'Atttached and sent to training!')
                    });
                })


            }).catch(err=>console.log(err));
            NotificationInfo('', 'successfully added', 'topRight')
        }
    }



    let getAllIntents = () => {
        request.get(`nlu/intent/`).then(res => {
            setShowAllIntent(res.data.results);
        })
    }


    let getAllUtternces= (page) => {
        request.get(`nlu/file_utterances`+'?page='+page).then(res => {
            setAllUtterances(res.data.results);
            setTotalUtterances(res.data.count);
        })
    }

    console.log('allUtterances', allUtterances)

    let testIntent = (e)=>{
        setIsTestUtteranceModalVisible(true);
    }

    let testUtterancesOnChange = (e)=>{
        setTestUtteranceVal(e.target.value);
    }




    let testUtterance = (e)=>{
        e.preventDefault();

        request.post('nlu/message/',
            {"data": testUtteranceVal}).then((res)=>{
            res.data.intents.map((v,i)=>{
                let obj = v;
                v.confidence = Math.round(v.confidence * 100)+ '%';
                return obj;
            });
            // res.data.intents = data;
            console.log([res.data]);
            setTestData([res.data]);
            // console.log(res);
        }).catch(err=>console.log(err));
    }



    const test_columns = [
        {
            title: 'Intent & Confidence',
            key: 'intent',
            dataIndex: 'intent',
            width: '25%',
            render: (_, record)=>{
                if (record.intents.length > 0) {
                    const value = record.intents[0];
                    return <Input name={'name'} value={value.name + '  ' + value.confidence} disabled={true} placeholder="Intent Name" />
                } else {
                    return <span>No intent Found</span>
                }
            }
        },
        {
            title: 'Entities & confidence',
            key: 'entities',
            dataIndex: 'entities',
            width: '25%',
            render: (_, record)=>{
                return record.entities.map((v,i)=><Input name={'entities'} value={v.body + '  ' + Math.round(v.confidence*100)+'%'} disabled={true} />);
            }
        },
        {
            title: 'Traits & confidence',
            key: 'traits',
            dataIndex: 'traits',
            width: '25%',
            render: (_, record)=>{
                return record.traits.map((v,i)=><Input name={'traits'} value={v.value + '  ' + Math.round(v.confidence*100)+'%'} disabled={true} />);
            }
        },
        // {
        //   title: 'Confidence',
        //   key: 'confidence',
        //   dataIndex: 'confidence',
        //   width: '25%',
        //   render: (_, record)=>{
        //     //   console.log(record);
        //     return <Input value={record.confidence} disabled={true} />
        //   }
        // }
    ];

    let handleUtteranceTestModalOk = (e)=>{
        setIsTestUtteranceModalVisible(false);
    }

    let handleUtteranceTestModalCancel = (e)=>{
        setIsTestUtteranceModalVisible(false);
    }
    let handleBulkUploadModalOk = (e)=>{
        setIsBulkUploadModalVisible(false);

        setCurrentIntentID(undefined)
        setSelectedIntentId(undefined)
        setIntentValue(undefined);
    }

    let handleBulkUploadModalCancel = (e)=>{
        setIsBulkUploadModalVisible(false);

        setCurrentIntentID(undefined)
        setSelectedIntentId(undefined)
        setIntentValue(undefined);
    }
    const bulkUploadHandler = (e) =>{
        e.preventDefault();
        setIsBulkUploadModalVisible(true)
    }

    
    return(
        <>
            <Header />
            <Layout className='layouts' style={{
                padding: '0 15px 15px'
            }}>
                <div className='___intents_section'>
                    <div className='left_main_aiflow'>
                        <h4>AI FLOWS</h4>
                        <ul className='d-flex item-center _mt_18 linklist'>
                            <li>
                                <Link className={`${history.location.pathname === '/response-log/' ? 'active' : null}`} to="/response-log/">All Utterances</Link>
                            </li>
                            <li>
                                <Link className={`${history.location.pathname === '/bulk-train/' ? 'active' : null}`} to="/bulk-train/">Bulk training</Link>
                            </li>
                            <li>
                                <Link className={`${history.location.pathname === '/profanity-filter/' ? 'active' : null}`} to="/profanity-filter/">Profanity filter</Link>
                            </li>
                            <div className='_test_button'>
                                <button type='button' onClick={testIntent} className='btn'>Test Utterances</button>
                            </div>
                        </ul>
                    </div>

                    <div className='main_bulk_retrains'>
                        <div className='bulk_retrain_top_section d-flex item-center space-between'>
                            <div className='upload_file_csv relative'>
                                {/* <form id="myForm" name="myForm">
                            <input type='file' accept='.csv' onChange={fileUploadHandler}/>
                            <input type="submit" value="Submit!" />
                        </form> */}
                                <button type='button' className='upload_file_csv_btn' onClick={bulkUploadHandler} >Bulk Import</button>
                            </div>
                            <button type='button' className='attach_btn' onClick={showAttachModal}>Attach Intent</button>
                        </div>

                        <div className='main_bulk_retrain table_scrolling'>

                            {
                                successfullyAdded === 'successfully created' && preload ?
                                    <Spin className='spinner' size="large" /> :
                                    <CTable
                                        pagination = {
                                            {
                                                onChange: page => {
                                                    getAllUtternces(page);
                                                    console.log(page);
                                                },
                                                pageSize: 20,
                                                total: totalUtterances
                                            }
                                        }
                                        selectedRowsCB={
                                            (data) => {
                                                setUtteranceAttach(data)
                                            }
                                        }
                                        {...
                                            {
                                                bordered: true,
                                                selectRowKey: 'id',
                                                selection: true,
                                                columns,
                                                data: allUtterances,
                                            }
                                        }
                                    />
                            }
                        </div>
                    </div>
                    <CModal {...{width:1000, title: "Test Utterance",
                        innerComponent: <>
                            <div className='utterance'>
                                <div className='input_utterance d-flex item-center justify-center'>

                                    <input type='text'
                                           onKeyUp={event => event.keyCode === 13 && event.target.value != "" && testUtterance(event)}
                                           value={testUtteranceVal}
                                           onChange={testUtterancesOnChange}
                                           placeholder='Type your utterance'/>
                                    <div className='train_button'>
                                        <button onClick={testUtterance} type='button' className='btn'>Test Utterance</button>
                                    </div>
                                </div>

                                <br/>
                                {testData && <CTable {...{columns: test_columns, bordered: true, pagination: false, data: testData, selection: false}} />}

                            </div>
                        </>
                        , modalIsVisible: isTestUtteranceModalVisible, handleOk: handleUtteranceTestModalOk, handleCancel: handleUtteranceTestModalCancel }}
                    />

                    <Modal className='attachment' title="Attachment" visible={isAttachModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <div className='d-flex item-center space-between'>
                                    <div className='intent_select'>
                                        <Select
                                            value={intentValue ? intentValue : 'Please select intent'}
                                            className={`${intentValue == undefined ? 'error_intent_select' : 'intent_select'}`}
                                            defaultValue="lucy"
                                            onChange={(v, option)=>intentHandler(v, option)}
                                            bordered={false}
                                        >
                                            {showAllIntent && showAllIntent.map(item => (
                                                <Option key={item.id}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </Col>
                            <Col span={15}>
                                <div className='attach_list attach_list_scroll'>
                                    <ul>
                                        {
                                            showSelectUtterance ? showSelectUtterance.map((value, i)=> {
                                                return <>
                                                    <li className='d-flex' key={value.id}>
                                                        <b className='bold_number'>{value.id}.</b>
                                                        {value.utterances}
                                                    </li>
                                                </>
                                            }): null
                                        }
                                    </ul>
                                </div>
                            </Col>
                            <Col span={1}>
                                <div className='attach_modal_list_divider'></div>
                            </Col>
                            <Col span={8}>
                                <div className='attach_list_btn mb_10'>
                                    <ul>
                                        {/*<li>
                                            <button type='button' className='attach_btn' onClick={addToIntentHandler}>Add to intent</button>
                                        </li>*/}
                                        <li>
                                            <button type='button' onClick={attachAndTrain} className='attach_btn'>Attach & Train</button>
                                        </li>
                                        {
                                            attacheBtnToggle &&
                                            <>
                                                {/* <li>
                                                <button type='button' className='attach_btn'>Schedule</button>
                                            </li>*/}
                                                <li>
                                                    <button type='button' className='attach_btn' onClick={goToViewIntent}>
                                                        Go to Intent
                                                    </button>
                                                </li>
                                            </>
                                        }

                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Modal>

                    {/* bulk upload modal */}

                    <CModal {...{width:1000, title: "Bulk Upload",
                        innerComponent: <>
                            <Row gutter={16}>
                                <div className='d-flex item-center justify-center'>
                                    <div className=''>
                                        <div className='intent_select'>
                                            <Select
                                                value={intentValue ? intentValue : 'Please select intent'}
                                                className={`${intentValue == undefined ? 'error_intent_select' : 'intent_select'}`}
                                                defaultValue="lucy"
                                                onChange={(v, option)=>intentHandler(v, option)}
                                                bordered={false}
                                            >
                                                {showAllIntent && showAllIntent.map(item => (
                                                    <Option key={item.id}>{item.name}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className='attach_list_btn'>
                                        <ul>

                                            <li className='upload_file_csv'>
                                                <input onChange={fileUploadHandler} type='file' />

                                                <button
                                                    type='button'

                                                    className='attach_btn'
                                                >
                                                    {uploadingDone ? 'Upload' : 'Uploding...'}
                                                </button>
                                            </li>

                                        </ul>
                                    </div>
                                    {intentValue !== undefined && <div className='attach_list_btn'>
                                        <ul>
                                            <li className='upload_file_csv'>

                                                <button
                                                    type='button'
                                                    onClick={goToViewIntent}
                                                    className='attach_btn'
                                                >
                                                    Goto Intent
                                                </button>
                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </Row>
                        </>
                        , modalIsVisible: isBulkUploadModalVisible,
                        handleOk: handleBulkUploadModalOk,
                        handleCancel: handleBulkUploadModalCancel }}
                    />
                </div>
            </Layout>
            <SidebarStatic />
        </>
    )
}

export default BulkRetrain;
