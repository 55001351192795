import React from 'react'
import {NavLink} from "react-router-dom";
function SidebarStatic(){
    return(
        <>
            <React.Fragment>
                <div className="sidebar sidebar-hide-to-small sidebar-shrink sidebar-gestures">
                    <div className="nano">
                        <div className="nano-content">
                            <ul className="pt-2">

                                <li>
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.00003" y="1" width="8.23529" height="8.23529" rx="2" stroke="#121F3E" stroke-width="1.5"/>
                                        <rect x="21" y="21" width="8.23529" height="8.23529" rx="2" transform="rotate(-180 21 21)" stroke="#121F3E" stroke-width="1.5"/>
                                        <path d="M12.7647 9.23529V3C12.7647 1.89543 13.6601 1 14.7647 1H19C20.1045 1 21 1.89543 21 3V7.23529C21 8.33986 20.1045 9.23529 19 9.23529H16.8823" stroke="#121F3E" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M9.23538 12.7647L9.23538 19C9.23538 20.1046 8.33995 21 7.23538 21L3.00009 21C1.89552 21 1.00009 20.1046 1.00009 19L1.00009 14.7647C1.00009 13.6601 1.89552 12.7647 3.00009 12.7647L5.11774 12.7647" stroke="#121F3E" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </li>


                                <li>
                                    <NavLink to="/automation">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="path-1-inside-1_102_39" fill="white">
                                                <rect x="15" width="5" height="5.26316" rx="1"/>
                                            </mask>
                                            <rect x="15" width="5" height="5.26316" rx="1" stroke="#6400AA" stroke-width="3" mask="url(#path-1-inside-1_102_39)"/>
                                            <mask id="path-2-inside-2_102_39" fill="white">
                                                <rect x="15" y="14.7368" width="5" height="5.26316" rx="1"/>
                                            </mask>
                                            <rect x="15" y="14.7368" width="5" height="5.26316" rx="1" stroke="#6400AA" stroke-width="3" mask="url(#path-2-inside-2_102_39)"/>
                                            <mask id="path-3-inside-3_102_39" fill="white">
                                                <rect x="3.05176e-05" y="7.36841" width="5" height="5.26316" rx="1"/>
                                            </mask>
                                            <rect x="3.05176e-05" y="7.36841" width="5" height="5.26316" rx="1" stroke="#6400AA" stroke-width="3" mask="url(#path-3-inside-3_102_39)"/>
                                            <mask id="path-4-inside-4_102_39" fill="white">
                                                <rect x="8.00003" y="7.36841" width="4.5" height="4.73684" rx="1"/>
                                            </mask>
                                            <rect x="8.00003" y="7.36841" width="4.5" height="4.73684" rx="1" stroke="#6400AA" stroke-width="3" mask="url(#path-4-inside-4_102_39)"/>
                                            <path d="M5.00003 10H8.00003" stroke="#6400AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10 7.36841V2.10526H15" stroke="#6400AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10 11.579V17.8947H15" stroke="#6400AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                    </NavLink>
                                </li>


                                <li>
                                    <NavLink to="/intent">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.16559 16.5556H13.4558M10.8113 1V2.11111M18.0159 3.92889L17.2155 4.71444M21 11H19.8679M10.8113 5.44548C11.9307 5.44548 13.0251 5.77127 13.9559 6.38166C14.8867 6.99205 15.6122 7.85962 16.0407 8.87468C16.4691 9.88974 16.5813 11.0067 16.363 12.0843C16.1448 13.162 15.6058 14.1519 14.8143 14.9289L14.1939 15.5367C13.8393 15.8848 13.5579 16.2982 13.366 16.753C13.1741 17.2079 13.0754 17.6954 13.0754 18.1878V18.7778C13.0754 19.3671 12.8369 19.9324 12.4123 20.3491C11.9877 20.7659 11.4118 21 10.8113 21C10.2108 21 9.63488 20.7659 9.21026 20.3491C8.78565 19.9324 8.5471 19.3671 8.5471 18.7778V18.1878C8.5471 17.1933 8.14408 16.2389 7.4286 15.5367" stroke="#121F3E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M4.48813 5.91285C4.69098 5.07666 5.88051 5.07666 6.08336 5.91285C6.1138 6.03847 6.17347 6.15512 6.25751 6.25332C6.34155 6.35152 6.4476 6.42849 6.56701 6.47797C6.68642 6.52744 6.81582 6.54802 6.94469 6.53802C7.07356 6.52803 7.19824 6.48775 7.3086 6.42047C8.04336 5.97285 8.88479 6.8138 8.43717 7.54904C8.36998 7.65935 8.32977 7.78396 8.3198 7.91274C8.30983 8.04152 8.33039 8.17083 8.37981 8.29017C8.42922 8.40951 8.5061 8.5155 8.60419 8.59954C8.70228 8.68358 8.81881 8.74328 8.94432 8.7738C9.78051 8.97666 9.78051 10.1662 8.94432 10.369C8.8187 10.3995 8.70204 10.4591 8.60384 10.5432C8.50564 10.6272 8.42867 10.7333 8.3792 10.8527C8.32973 10.9721 8.30915 11.1015 8.31914 11.2304C8.32913 11.3592 8.36941 11.4839 8.4367 11.5943C8.88432 12.329 8.04336 13.1705 7.30813 12.7228C7.19781 12.6557 7.0732 12.6154 6.94442 12.6055C6.81564 12.5955 6.68633 12.6161 6.56699 12.6655C6.44765 12.7149 6.34166 12.7918 6.25762 12.8899C6.17359 12.988 6.11388 13.1045 6.08336 13.23C5.88051 14.0662 4.69098 14.0662 4.48813 13.23C4.45769 13.1044 4.39802 12.9877 4.31398 12.8895C4.22994 12.7913 4.12389 12.7143 4.00448 12.6649C3.88507 12.6154 3.75567 12.5948 3.6268 12.6048C3.49793 12.6148 3.37325 12.6551 3.26289 12.7224C2.52813 13.17 1.6867 12.329 2.13432 11.5938C2.20151 11.4835 2.24172 11.3589 2.25169 11.2301C2.26166 11.1013 2.2411 10.972 2.19168 10.8527C2.14227 10.7333 2.06539 10.6273 1.9673 10.5433C1.86921 10.4593 1.75268 10.3996 1.62717 10.369C0.790983 10.1662 0.790983 8.97666 1.62717 8.7738C1.75279 8.74336 1.86945 8.6837 1.96765 8.59965C2.06585 8.51561 2.14282 8.40957 2.19229 8.29016C2.24176 8.17075 2.26234 8.04134 2.25235 7.91247C2.24236 7.78361 2.20208 7.65892 2.13479 7.54856C1.68717 6.8138 2.52813 5.97237 3.26336 6.41999C3.73765 6.70951 4.3567 6.45332 4.48813 5.91285Z" stroke="#121F3E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M4.72003 10.052L4.68703 10.187H5.04703L5.01403 10.052C4.99003 9.958 4.96603 9.856 4.94203 9.746C4.91803 9.636 4.89403 9.532 4.87003 9.434H4.85803C4.83603 9.534 4.81303 9.639 4.78903 9.749C4.76703 9.857 4.74403 9.958 4.72003 10.052ZM3.97003 11L4.56403 9.05H5.18803L5.78203 11H5.23603L5.14003 10.586H4.59403L4.49803 11H3.97003ZM5.96798 11V9.05H6.48398V11H5.96798Z" fill="#121F3E"/>
                                        </svg>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </>
    );
}
export default SidebarStatic;