import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Login from './components/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import Analytics from './components/analytics/Analytics';
import Retrain from './components/retrain/Retrain';
// import TopFlows from './components/topFlows/TopFlows';
import RecentUsers from './components/recentUsers/RecentUsers';
import People from './components/people/People';
import Automation from './components/automation/Automation';
import './App.css';
import Test from "./components/test/Test";
import Intent from './components/ai_flow/global-training/Intents'
import "antd/dist/antd.css";
import BulkRetrain from "./components/ai_flow/ai-flow/Bulk";
import Response from './components/ai_flow/Response-Train/Response-log'
import ProfanityFilter from './components/ai_flow/ai-flow/ProfanityFilter'

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {

    }
    console.log(this.props);
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div style={{ height: '100%' }}>

            <Switch>
                <Route exact path='/' render={props => <Automation {...props} />} />
                {/* <Route exact path='/analytics' render={props => <Analytics {...props} />} /> */}
                <Route exact path='/analytics/activity' render={props => <Analytics {...props} />} />
                {/* <Route exact path='/analytics/topFlows' render={props => <TopFlows {...props} />} /> */}
                <Route exact path='/analytics/recentUsers' render={props => <RecentUsers {...props} />} />
                <Route exact path='/leadgen' render={props => <People {...props} />} />
                <Route exact path='/retrain' render={props => <Retrain {...props} />} />
                <Route exact path='/automation' render={props => <Automation {...props} />} />
                <Route path='/automation/:flow' render={props => <Automation {...props} />} />
                <Route path='/test' render={props => <Test {...props} />} />
                <Route path='/intent/'>
                    <Intent />
                </Route>
                <Route path='/bulk-train/'>
                    <BulkRetrain />
                </Route>

            </Switch>

            {/* <Auth> */}
              <Switch>
                <Route exact path='/login' render={props => <Login {...props} />} />
              </Switch>
            {/* </Auth> */}
          </div>
            <Route exact path='/profanity-filter/'
                   render={
                       (props) =>
                           <ProfanityFilter props={props} />
                   }
            >

            </Route>
            <Route exact path='/intents/:id/response-log/'
                   render={
                       (props) =>
                       <Response props={props} />
                   }
            />
            <Route exact path='/response-log/'
                   render={(props) => <Response props={props} />}
            />
            <Route exact path='/profanity-filter/' render={(props) => <ProfanityFilter props={props} />}/>
        </Router>
      </Provider>
    );
  }
}



export default App;
