import * as React from 'react'
import './header.scss';
import { Menu, Dropdown } from 'antd';

import {Link} from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { CaretDownOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

interface Props {

}

export const Header: React.FC<Props> = () => {
    let history = useHistory();

    const logOut = () => {
        localStorage.removeItem('token');
        history.push('/login')
    }
    const menu = (
        <Menu>
            <Menu.Item key="0">
                <div className="genex_logo">
                    <img src="https://pbs.twimg.com/profile_images/965446181248647168/M0VY2MqI_400x400.jpg"/>
                    <p>Skitto</p>
                </div>
            </Menu.Item>
        </Menu>
    );
    return (
        <React.Fragment>

            <nav className="navbar navbar-expand-lg navbar-light header new_header">
                <div className="header_item_center header_width">
                    <div className="left_header_flex">
                        <div className="logo d-flex">
                            {/*<Link to="/"><img src="https://chatlogy.ai/images/blue_chatlogy.png" /> </Link>*/}
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30 0.25H10C4.61522 0.25 0.25 4.61522 0.25 10V30C0.25 35.3848 4.61522 39.75 10 39.75H30C35.3848 39.75 39.75 35.3848 39.75 30V10C39.75 4.61522 35.3848 0.25 30 0.25Z" stroke="#C1C1C1" stroke-width="0.5"/>
                                <path d="M13.9019 17.766C14.6421 17.766 15.3656 17.5465 15.981 17.1353C16.5964 16.7241 17.076 16.1396 17.3592 15.4558C17.6424 14.7719 17.7164 14.0195 17.5719 13.2936C17.4275 12.5677 17.071 11.9009 16.5476 11.3776C16.0241 10.8543 15.3573 10.498 14.6313 10.3538C13.9054 10.2095 13.153 10.2837 12.4692 10.5671C11.7855 10.8505 11.2011 11.3303 10.7901 11.9458C10.379 12.5613 10.1597 13.2848 10.1599 14.025C10.1612 15.0169 10.5559 15.9678 11.2574 16.6692C11.9589 17.3705 12.91 17.7649 13.9019 17.766Z" fill="#B7BEC2"/>
                                <path d="M26.012 17.766C26.7522 17.7662 27.4758 17.5469 28.0913 17.1358C28.7068 16.7247 29.1866 16.1402 29.47 15.4564C29.7533 14.7726 29.8275 14.0202 29.6831 13.2942C29.5388 12.5682 29.1824 11.9014 28.659 11.378C28.1356 10.8546 27.4687 10.4982 26.7428 10.3538C26.0168 10.2095 25.2643 10.2837 24.5805 10.567C23.8967 10.8504 23.3123 11.3301 22.9012 11.9457C22.4901 12.5612 22.2708 13.2848 22.271 14.025C22.2723 15.0168 22.6669 15.9675 23.3682 16.6688C24.0695 17.3701 25.0202 17.7647 26.012 17.766Z" fill="#3D4A68"/>
                                <path d="M13.9881 22.234C13.2482 22.234 12.5249 22.4534 11.9097 22.8645C11.2945 23.2755 10.815 23.8598 10.5318 24.5434C10.2487 25.227 10.1746 25.9792 10.319 26.7048C10.4633 27.4305 10.8196 28.0971 11.3428 28.6203C11.866 29.1435 12.5326 29.4998 13.2582 29.6441C13.9839 29.7885 14.7361 29.7144 15.4197 29.4312C16.1033 29.1481 16.6875 28.6686 17.0986 28.0534C17.5097 27.4382 17.7291 26.7149 17.7291 25.975C17.7277 24.9832 17.3332 24.0325 16.6319 23.3312C15.9306 22.6299 14.9798 22.2353 13.9881 22.234Z" fill="#3D4A68"/>
                                <path d="M26.096 22.234C25.3561 22.234 24.6328 22.4534 24.0176 22.8645C23.4024 23.2755 22.9229 23.8598 22.6397 24.5434C22.3566 25.227 22.2825 25.9792 22.4269 26.7048C22.5712 27.4305 22.9275 28.0971 23.4507 28.6203C23.9739 29.1435 24.6405 29.4998 25.3661 29.6441C26.0918 29.7885 26.844 29.7144 27.5276 29.4312C28.2112 29.1481 28.7954 28.6686 29.2065 28.0534C29.6176 27.4382 29.837 26.7149 29.837 25.975C29.8357 24.9832 29.4411 24.0325 28.7398 23.3312C28.0385 22.6299 27.0878 22.2353 26.096 22.234Z" fill="#B7BEC2"/>
                            </svg>


                                <svg style={{marginLeft: '15px'}} width="125" height="33" viewBox="0 0 125 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 18.3884C0 13.8104 3.66057 10.0992 8.17611 10.0992C12.6916 10.0992 16.3522 13.8104 16.3522 18.3884V30.1678H13.4834V18.3884C13.4834 15.4167 11.1072 13.0077 8.17611 13.0077C5.24497 13.0077 2.86881 15.4167 2.86881 18.3884V30.1678H0V18.3884Z" fill="#6400AA"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3789 18.3884C13.3789 13.8104 17.0395 10.0992 21.555 10.0992C26.0706 10.0992 29.7311 13.8104 29.7311 18.3884V30.1678H26.8623V18.3884C26.8623 15.4167 24.4862 13.0077 21.555 13.0077C18.6239 13.0077 16.2477 15.4167 16.2477 18.3884V30.1678H13.3789V18.3884Z" fill="#6400AA"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.1038 21.436H53.5163V19.9829C53.5163 19.6873 53.5039 19.3942 53.4797 19.1044C53.455 18.8095 53.4098 18.5799 53.3764 18.4104C53.3674 18.3649 53.3593 18.3237 53.3527 18.2867C53.2937 17.9579 53.2193 17.6345 53.1302 17.3176C53.0287 16.9562 52.9081 16.6033 52.7697 16.2602C51.3115 12.6441 47.8704 10.0992 43.8536 10.0992C38.517 10.0992 34.1909 14.5917 34.1909 20.1335C34.1909 25.6753 38.517 30.1678 43.8536 30.1678C47.8704 30.1678 51.3115 27.6229 52.7697 24.0068L50.1883 22.8843C49.1505 25.458 46.7033 27.2616 43.8536 27.2616C40.491 27.2616 37.6932 24.7508 37.1038 21.436ZM43.8536 13.0054C40.5936 13.0054 37.8645 15.3653 37.1639 18.5299H50.5436C50.5139 18.3957 50.4806 18.263 50.4438 18.1319C50.3718 17.8759 50.2864 17.6259 50.1883 17.3827C49.1505 14.809 46.7033 13.0054 43.8536 13.0054Z" fill="#6400AA"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M85.4775 13.0077C82.9215 13.0077 80.8495 15.1563 80.8495 17.8067V30.1678H78.0447V17.8067C78.0447 13.55 81.3725 10.0992 85.4775 10.0992V13.0077Z" fill="#6400AA"/>
                                    <path d="M76.0481 11.1452L66.3721 30.0159C66.2979 30.1605 66.1491 30.2514 65.9867 30.2514L63.2309 30.2514C63.0578 30.2514 62.9013 30.1483 62.833 29.9892L54.6891 11.0244C54.5663 10.7386 54.776 10.4204 55.0871 10.4204L57.9254 10.4204C58.1014 10.4204 58.2599 10.5268 58.3264 10.6898L64.3312 25.3989C64.4725 25.7449 64.9562 25.7616 65.121 25.4262L72.3288 10.7566C72.4016 10.6084 72.5524 10.5145 72.7176 10.5145L75.6627 10.5145C75.9866 10.5145 76.1959 10.857 76.0481 11.1452Z" fill="#121F3E"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M62.833 29.9892C62.9013 30.1483 63.0578 30.2514 63.2309 30.2514L65.9867 30.2514C66.1491 30.2514 66.2979 30.1605 66.3721 30.0159L76.0481 11.1452C76.1959 10.857 75.9866 10.5145 75.6627 10.5145L72.7176 10.5145C72.5524 10.5145 72.4016 10.6084 72.3288 10.7566L65.121 25.4262C64.9562 25.7616 64.4725 25.7449 64.3312 25.3989L58.3264 10.6898C58.2599 10.5268 58.1014 10.4204 57.9254 10.4204L55.0871 10.4204C54.776 10.4204 54.5663 10.7386 54.6891 11.0244L62.833 29.9892Z" fill="#6400AA"/>
                                    <path d="M101.129 17.9283L101.129 21.234L110.171 30.3204L115.047 30.3204L104.078 19.6785L114.916 10.5406L110.141 10.5406L101.129 17.9283Z" fill="#121F3E"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M114.916 10.5406L110.141 10.5406L101.129 17.9283L101.129 21.234L110.171 30.3204L115.047 30.3204L104.078 19.6785L114.916 10.5406Z" fill="#6400AA"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M93.6535 10.0992L93.6536 30.1678L90.6804 30.1678L90.6804 10.0992L93.6535 10.0992Z" fill="#6400AA"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M101.83 3.40961L101.83 30.1678L98.8567 30.1678L98.8567 3.40961L101.83 3.40961Z" fill="#6400AA"/>
                                    <path d="M94.397 5.63945C94.397 6.87097 93.3986 7.8693 92.1671 7.8693C90.9356 7.8693 89.9373 6.87097 89.9373 5.63945C89.9373 4.40794 90.9356 3.40961 92.1671 3.40961C93.3986 3.40961 94.397 4.40794 94.397 5.63945Z" fill="#6400AA"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M92.1671 7.72065C93.3165 7.72065 94.2483 6.78886 94.2483 5.63945C94.2483 4.49004 93.3165 3.55826 92.1671 3.55826C91.0177 3.55826 90.0859 4.49004 90.0859 5.63945C90.0859 6.78886 91.0177 7.72065 92.1671 7.72065ZM92.1671 7.8693C93.3986 7.8693 94.397 6.87097 94.397 5.63945C94.397 4.40794 93.3986 3.40961 92.1671 3.40961C90.9356 3.40961 89.9373 4.40794 89.9373 5.63945C89.9373 6.87097 90.9356 7.8693 92.1671 7.8693Z" fill="#6400AA"/>
                                </svg>


                        </div>

                        <Dropdown overlay={menu} trigger={['click']}>
                            <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <div className="genex_logo">
                                    <img src="https://pbs.twimg.com/profile_images/965446181248647168/M0VY2MqI_400x400.jpg"/>
                                    <p>
                                        Grameenphone
                                        <CaretDownOutlined className='fill_arrow' />
                                    </p>
                                </div>
                            </div>
                        </Dropdown>
                    </div>
                    <div className="right_header_flex">
                        <div className="genex_logo">
                            <img src="assets/Olivia.png"/>
                            <p>Olivia</p>
                        </div>
                        <div
                            style={{ cursor: "pointer", color: "gray",}}
                            className="label log_out"
                            onClick={logOut}
                        >
                            <FiLogOut title="Logout" className="sidebar-icon" />
                        </div>
                    </div>

                </div>

            </nav>
        </React.Fragment>
    );
}
